import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';

import Quarry from './assets/quarry.png';

const QuarryPage = () => (
  <div className="w-full h-screen flex items-center justify-center bg-black">
    <img src={Quarry} alt="Quarry" className="max-w-full max-h-full object-contain" />
  </div>
);

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quarry" element={<QuarryPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;