import React, {useEffect} from "react";
import { X } from "lucide-react";

export const Future = ({ onClose }: { onClose: () => void }) => {
  useEffect(() => {
    window.scrollTo(0, -1);
  }, []);
  return (
    <div className="text-white h-screen flex flex-col">
      <header className="px-4 py-6">
        <div className="flex items-center justify-between relative">
          <h2 className="text-lg font-medium">Tyler Bordeaux</h2>
          <h1 className="text-lg font-bold absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            Future
          </h1>
          <button onClick={onClose} className="text-white hover:text-gray-300 transition-colors">
            <X size={24} />
          </button>
        </div>
        <div className="h-px bg-[#292828] mt-4"></div>
      </header>
      
      <div className="flex-grow flex items-center justify-center">
        <div className="max-w-2xl mx-auto space-y-8 px-4">
          <div className="space-y-4">
            <p className="text-3xl font-bold">
              In a world that's changing quickly, the biggest risk is not taking any risk.
            </p>
            <p className="text-gray-400 text-lg">- Mark Zuckerberg</p>
          </div>
          
          <div className="space-y-6">
            <p className="text-lg">
              I believe society will drastically change in the next two decades. The birth of artificial general intelligence will dwarf the impact of the Industrial Revolution. Gene editing will obliterate diseases we once thought incurable. Brain–computer interfaces will allow us to utilize artificial intelligence without touching a keyboard.
            </p>
            
            <p className="text-lg">
              I hope to found or join a startup in one of these areas, leaving my mark on the world through innovation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};