import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { cn } from "../../lib/utils";

type ButtonInfo = {
  text: string;
  url: string;
};

type Card = {
  id: number;
  content: JSX.Element | React.ReactNode | string;
  className: string;
  thumbnail: string;
  buttons?: ButtonInfo[];
};

export const LayoutGrid = ({ cards }: { cards: Card[] }) => {
  const [selected, setSelected] = useState<Card | null>(null);
  const [lastSelected, setLastSelected] = useState<Card | null>(null);
  const [isWideScreen, setIsWideScreen] = useState<boolean>(false);
  const [aspec, setAspec] = useState<string>('');

  useEffect(() => {
    const updateAspectRatio = () => {
      const aspectRatio = window.innerWidth / window.innerHeight;
      setAspec(aspectRatio.toString());
      setIsWideScreen(aspectRatio > 2);
    };

    updateAspectRatio(); // Initial check
    window.addEventListener("resize", updateAspectRatio);

    return () => {
      window.removeEventListener("resize", updateAspectRatio);
    };
  }, []);

  const handleClick = (card: Card) => {
    setLastSelected(selected);
    setSelected(card);
  };

  const handleOutsideClick = () => {
    setLastSelected(selected);
    setSelected(null);
  };

  return (
    <div className="w-full h-full p-10 grid grid-cols-1 md:grid-cols-3 max-w-7xl mx-auto gap-4 relative">
      {/* <p>{aspec} {isWideScreen ? "true" : "false"}</p> */}
      {/* <p>{selected?.id}</p> */}
      {cards.map((card, i) => (
        <div key={i} className={cn(card.className, "")}>
          <motion.div
            onClick={() => handleClick(card)}
            className={cn(
              card.className,
              "relative overflow-hidden",
              selected?.id === card.id
                ? "rounded-lg cursor-pointer absolute inset-0 h-auto min-h-[50%] w-full md:w-1/2 m-auto z-50 flex justify-center items-end flex-wrap flex-col"
                : lastSelected?.id === card.id
                ? "z-40 bg-white rounded-xl h-full w-full"
                : "bg-white rounded-xl h-full w-full"
            )}
            style={{
              maxHeight: selected?.id === card.id && isWideScreen ? '80vh' : selected?.id === card.id ? '50vh' : '100vh',
              overflowY: selected?.id === card.id && isWideScreen ? 'auto' : 'initial',
            }}
            layoutId={`card-${card.id}`}
          >
            {selected?.id === card.id && <SelectedCard selected={selected} />}
            <ImageComponent card={card} />
          </motion.div>
        </div>
      ))}
      <motion.div
        onClick={handleOutsideClick}
        className={cn(
          "absolute h-full w-full left-0 top-0 opacity-0 z-10",
          selected?.id ? "pointer-events-auto" : "pointer-events-none"
        )}
        animate={{ opacity: selected?.id ? 0.3 : 0 }}
      />
    </div>
  );
};

const ImageComponent = ({ card }: { card: Card }) => {
  return (
    <motion.div
      layoutId={`image-${card.id}-image`}
      className="absolute inset-0 h-full w-full overflow-hidden"
    >
      <motion.img
        src={card.thumbnail}
        className={cn(
          "object-cover object-center w-full h-full transition duration-200"
        )}
        alt="thumbnail"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      />
    </motion.div>
  );
};

const SelectedCard = ({ selected }: { selected: Card | null }) => {
  return (
    <div className="bg-transparent w-full h-full flex flex-col justify-end rounded-lg shadow-2xl relative z-[60] overflow-hidden">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="absolute inset-0 h-full w-full bg-black opacity-60 z-10"
      />
      <motion.div
        layoutId={`content-${selected?.id}`}
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        className="relative px-8 pb-8 z-[70] overflow-y-auto"
      >
        {selected?.content}
        {selected?.buttons && selected.buttons.length > 0 && (
          <div className="mt-4 space-y-3">
            {selected.buttons.map((button, index) => (
              <a
                key={index}
                href={button.url}
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full py-2 px-4 bg-white bg-opacity-10 hover:bg-opacity-20 text-white text-center border border-white border-opacity-20 rounded-full transition-all duration-200 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
                onClick={(e) => e.stopPropagation()} // Prevent card from being deselected
              >
                {button.text}
              </a>
            ))}
          </div>
        )}
      </motion.div>
    </div>
  );
};
