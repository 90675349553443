import React, {useEffect, useRef, useState} from "react";
import { X } from "lucide-react";
import { ParallaxScroll } from "../components/ui/parallax-scroll";

import One from "../assets/about/1.jpg"
import Two from "../assets/about/2.jpg"
import Three from "../assets/about/3.jpg"
import Four from "../assets/about/4.jpg"
import Five from "../assets/about/5.jpg"
import Six from "../assets/about/6.jpg"
import Seven from "../assets/about/7.jpg"
import Eight from "../assets/about/8.jpg"
import Nine from "../assets/about/9.jpg"
import Ten from "../assets/about/10.jpg"
import Eleven from "../assets/about/11.jpg"
import Twelve from "../assets/about/12.jpg"
import Thirteen from "../assets/about/13.jpg"
import Fourteen from "../assets/about/14.jpg"
import Fifteen from "../assets/about/15.jpg"
import Sixteen from "../assets/about/16.jpg"
import Seventeen from "../assets/about/17.jpg"
import Eighteen from "../assets/about/18.jpg"
import Nineteen from "../assets/about/19.jpg"

var images = [
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirteen,
  Fourteen,
  Fifteen,
  Sixteen,
  Seventeen,
  Eighteen,
  Nineteen
];

images = images.sort(() => Math.random() - 0.5);
export const About = ({ onClose }: { onClose: () => void }) => {
  const [showParallax, setShowParallax] = useState(false);

  useEffect(() => {
    window.scrollTo(0, -1);
  }, []);

  const toggleView = () => {
    
    setShowParallax(!showParallax);

    window.scrollTo({
      top: -1,
      });
  };

  return (
    <div className="text-white h-screen flex flex-col">
      <div className="">
        <header className="px-4 py-6">
          <div className="flex items-center justify-between relative">
            <h2 className="text-lg font-medium">Tyler Bordeaux</h2>
            <h1 className="text-lg font-bold absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              About
            </h1>
            <button onClick={onClose} className="text-white hover:text-gray-300 transition-colors">
              <X size={24} />
            </button>
          </div>
          <div className="h-px bg-[#292828] mt-4"></div>
        </header>
      </div>
      
      {!showParallax ? (
        <div className="flex-grow flex items-center justify-center px-4 overflow-auto -mt-16">
          <div className="max-w-2xl space-y-4">
            <p className="text-base sm:text-lg md:text-xl">
              I am currently a senior at <a href="https://www.shaker.org" className="text-blue-400 hover:underline">Shaker Heights High School</a>. 
              At Shaker, I am president of investing club, co-president of robotics and engineering club, 
              co-president of competitive programming club, vice president of Spanish service club, captain of the track team and a varsity cross country runner.
            </p>
            <p className="text-base sm:text-lg md:text-xl">
              Outside of school I do research with <a href="https://github.com/quant-cwru" className="text-blue-400 hover:underline">quant[at]CWRU</a> and NextGen ML, volunteer with <a href="https://www.youthchallengesports.com/" className="text-blue-400 hover:underline">Youth Challenge</a> and <a href="https://www.cerealcodes.org/" className="text-blue-400 hover:underline">Cereal Codes</a>, and build apps through my company Bordeaux Ventures LLC.
            </p>
            <p className="text-base sm:text-lg md:text-xl">
              My favorite books are <a href="https://www.goodreads.com/book/show/30659.Meditations" className="text-blue-400 hover:underline"><i>Meditations</i></a> by Marcus Aurelius, <a href="https://www.goodreads.com/book/show/55338968-the-cold-start-problem" className="text-blue-400 hover:underline"><i>The Cold Start Problem</i></a> by Andrew Chen, and <a href="https://www.goodreads.com/book/show/31920777-american-kingpin" className="text-blue-400 hover:underline"><i>American Kingpin</i></a> by Nick Bilton. My favorite movies are <a href="https://www.imdb.com/title/tt7775622/" className="text-blue-400 hover:underline"><i>Free Solo</i></a> and <a href="https://www.imdb.com/title/tt1285016/" className="text-blue-400 hover:underline"><i>The Social Network</i></a>.
            </p>
            <p className="text-base sm:text-lg md:text-xl">
              In my free time I like to play <a href="https://spikeball.com" className="text-blue-400 hover:underline">spikeball</a> and pickleball with friends, 
              board games with family, and walk <a href="/quarry" className="text-blue-400 hover:underline" target="_blank" rel="noopener noreferrer">my dog Quarry</a>.
            </p>
          </div>
        </div>
      ) : (
        <div className="flex-grow overflow-auto">
          <ParallaxScroll images={images} />
        </div>
      )}
      
      <div className="fixed bottom-8 left-1/2 transform -translate-x-1/2">
        <button
          onClick={toggleView}
          className="px-6 py-2 bg-white bg-opacity-10 rounded-full text-white hover:bg-opacity-20 transition-all duration-300 backdrop-blur-sm"
        >
          {showParallax ? "Bio" : "Photos"}
        </button>
      </div>
    </div>
  );
};